@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Damion&family=Lilita+One&display=swap');


html,
body,
#root {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  /* background-color: #ffce60; */
  background-color: #ffce60;
  background-image: url("/public/bg1.png");
  background-size: 50%;
  background-repeat: repeat;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  overscroll-behavior: none;
}

body {
  background: #2a2a2a;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Helvetica Neue', Helvetica, Arial, Roboto,
    Ubuntu, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 0px;
  overflow: hidden;
}

li {
  display: block;
  overflow: hidden;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0px;
}

.info {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  transform: translate3d(-50%, -50%, 0);
  border-left: 1px solid black;
  padding-left: 40px;
  user-select: none;
  pointer-events: none;
}

.info p {
  font-size: 16px;
  line-height: 1.25em;
  margin-bottom: 0.2em;
  font-family: 'Lilita One', cursive;
  color: rgb(71, 71, 71);
}

.accent {
  -webkit-text-stroke: 1px rgb(71, 71, 71);
  color: transparent;
}

.price {
  display: inline-block;
  font-family: 'Major Mono Display', monospace;
  background: black;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.info h1 {
  font-family: 'Major Mono Display', monospace;
  font-size: 2em;
  margin-bottom: 0.5em;
}

.info h3 {
  font-weight: 800;
  font-size: 5em;
  letter-spacing: -4px;
  line-height: 1em;
  margin: 0;
  font-family: 'Lilita One', cursive;
  color: rgb(71, 71, 71);
}

.info h4 {
  font-weight: 300;
  font-size: 1.15em;
  letter-spacing: -1px;
  margin: 10px 0;
  font-family: 'Damion', cursive;
  color: rgb(71, 71, 71);
}

a {
  pointer-events: all;
  color: #141b24;
  text-decoration: none;
  animation: color-change 10s infinite;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes color-change {
  0% { color: #141b24; }
  20% { color: #141b24; }
  40% { color: white; }
  60% { color: white; }
  80% { color: #141b24; }
  100% { color: #141b24; }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 4s ease 0s forwards;
}